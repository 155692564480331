<script>
import {
  ArrowUpIcon,
  CheckIcon,
  MapPinIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "vue-feather-icons";

import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
import Skeleton from "@/view/front/components/skeleton";
import IndexService from "@/MainServices/IndexService.js";
import Multiselect from "vue-multiselect";
import moment from "moment";

/**
 * Page-jobs component
 */
export default {
  data() {
    return {
      auth: localStorage.getItem("id_token") !== null ? true : false,
      token: `Bearer ${localStorage.getItem("token")}`,
      loading: true,
      mediaUrl: MEDIA_URL,
      apiUrl: API_URL,
      data: [],
      cities: [],
      originalData: [],
      show_load_more: true,
      page_num: 1,

      jobFunctions: [],
      jobTypes: [],
      jobShifts: [],
      experiences: [],
      jobIndustries: [],
      viewsData: null,
      selected_job_functions: [],
      selected_job_type: [],
      selected_job_shift: [],
      selected_job_experience: [],
      selected_job_industry: [],
      salary_range: [],
      city: [],
      desiredSalaryOptions: [],
      detail: null,
      url_shaire: null,
      date_posted: "Anytime",
      datePostedItems: [],
      posted_by: "Anyone",
      postedByItems: [],
      saveJobSuccess: "",
    };
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    ArrowUpIcon,
    CheckIcon,
    MapPinIcon,
    Skeleton,
    Multiselect,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
  },
  mounted() {
    this.getResults();
    this.getViewsData();
    this.getJobFunctions();
    this.getJobTypes();
    this.getJobShifts();
    this.getJobCareerLevel();
    this.getJobIndustries();
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    getResults() {
      this.loading = true;
      axios
        .get(`${this.apiUrl}/get-jobs?page=${this.page_num}`)
        .then((response) => {
          this.data = [...this.data, ...response.data.data];
          this.originalData = [...this.originalData, ...response.data.data];
          this.loading = false;
          if (response.data.next_page_url == null) {
            this.show_load_more = false;
          }
          IndexService.getCities().then((res) => {
            res.data.map((el) => {
              this.cities.push({
                id: el.id,
                value: el.city_name,
              });
            });
            var s = [
              "Upto 20,000",
              "20,001 - 40,000",
              "40,001 - 60,000",
              "60,001 - 80,000",
              "80,001 - 100,000",
              "100,001 - 125,000",
              "125,001 - 150,000",
              "150,001 - 175,000",
              "175,001 - 225,000",
              "225,001 - 275,000",
              "275,001 - 300,000",
              "300,000 - 350,000",
              "350,000 +",
            ];
            s.map((el, i) => {
              this.desiredSalaryOptions.push({
                id: i,
                value: el,
              });
            });
            this.desiredSalaryOptions.unshift(
              ...[{ id: "null", value: "Any salary" }]
            );
          });
        });
    },

    goToCity(item) {
      this.$router.replace({
        path: "/jobs/Pakistan/" + item,
        params: {
          search_text: this.search_text,
          selected_cities: [{ id: 1, value: item }],
        },
      });
    },

    makeTitle(slug) {
      var words = slug.split("-");
      for (var i = 0; i < words.length; i++) {
        var word = words[i];
        words[i] = word.charAt(0).toUpperCase() + word.slice(1);
      }
      return words.join(" ").replace("  ", " & ");
    },

    changeSelect(n) {
      this.data = this.originalData;
      if (n.target.value === "all") {
        this.originalData = this.data;
        return;
      }
      var temp = [];
      this.data.forEach((element) => {
        if (element.job_type.indexOf(n.target.value) !== -1) {
          temp.push(element);
        }
      });
      this.data = temp;
    },
    loadmore() {
      this.page_num = this.page_num + 1;
      this.getResults();
    },

    getJobFunctions() {
      this.loading = true;
      axios.get(`${this.apiUrl}/get-job-sectors`).then((response) => {
        this.jobFunctions = response.data;
      });
    },
    getJobTypes() {
      this.loading = true;
      axios.get(`${this.apiUrl}/get-job-types`).then((response) => {
        this.jobTypes = response.data;
      });
    },
    getJobShifts() {
      this.loading = true;
      axios.get(`${this.apiUrl}/get-job-shifts`).then((response) => {
        this.jobShifts = response.data;
      });
    },
    getJobCareerLevel() {
      this.loading = true;
      axios.get(`${this.apiUrl}/get-job-career-levels`).then((response) => {
        this.experiences = response.data;
      });
    },
    getJobIndustries() {
      this.loading = true;
      axios.get(`${this.apiUrl}/get-job-industries`).then((response) => {
        this.jobIndustries = response.data;
      });
    },
    getViewsData() {
      this.loading = true;
      axios.get(`${this.apiUrl}/get-views-data`).then((response) => {
        this.datePostedItems = [
          { title: "Anytime", value: "Anytime", days: "Anytime" },
          { title: "Today", value: response.data.today_job_count, days: 1 },
          {
            title: "Last Three Days",
            value: response.data.last_three_days_job_count,
            days: 3,
          },
          {
            title: "Last Week",
            value: response.data.last_week_job_count,
            days: 7,
          },
          {
            title: "Last Two Weeks",
            value: response.data.last_two_weeks_job_count,
            days: 14,
          },
        ];
        this.postedByItems = [
          { title: "Anyone", value: "Anyone", postedBy: "Anyone" },
          {
            title: "Recruitment Agencies",
            value: response.data.posted_by_agency,
            postedBy: "Recruitment Agencies",
          },
          {
            title: "Direct Employers",
            value: response.data.posted_by_employer,
            postedBy: "Direct Employers",
          },
          {
            title: "job4u.pk Network",
            value: response.data.posted_by_job4u,
            postedBy: "job4u Network",
          },
        ];
      });
    },
    filterSearch() {
      var check = false;
      this.salary_range.forEach((element) => {
        if (element.value == "Any salary") {
          check = true;
        }
      });
      axios
        .post(`${this.apiUrl}/filter-search-jobs`, {
          job_functions: this.selected_job_functions,
          job_type: this.selected_job_type,
          job_shift: this.selected_job_shift,
          salary_range: this.salary_range,
          city: this.city,
          experience: this.selected_job_experience,
          salary_range_any: check,
          date_posted: this.date_posted,
          posted_by: this.posted_by,
        })
        .then((response) => {
          this.data = response.data;
          this.loading = false;
          if (this.data.length < 12) {
            this.show_load_more = false;
          } else {
            this.show_load_more = true;
          }
        });
    },
    clearFilter() {
      this.search();
    },
    search() {
      axios
        .post(`${this.apiUrl}/search-jobs`, {
          search_text: this.search_text,
          selected_cities: this.selected_cities,
          description_search: this.description_search,
          page_num: this.page_num,
        })
        .then((response) => {
          this.data = response.data;
          this.loading = false;
          if (this.data.length < 12) {
            this.show_load_more = false;
          } else {
            this.show_load_more = true;
          }
        });
    },
    _jobFunctionSelection(event) {
      if (event.target.checked) {
        if (!this.selected_job_functions.includes(event.target.value)) {
          var temp = this.selected_job_functions;
          temp.push(event.target.value);
          this.selected_job_functions = temp;
        }
      } else {
        var temp = [];
        this.selected_job_functions.forEach((element) => {
          if (element !== event.target.value) {
            temp.push(event.target.value);
          }
        });
        this.selected_job_functions = temp;
      }
      // console.log(this.selected_job_functions);
    },
    _jobTypeSelection(event) {
      if (event.target.checked) {
        if (!this.selected_job_type.includes(event.target.value)) {
          var temp = this.selected_job_type;
          temp.push(event.target.value);
          this.selected_job_type = temp;
        }
      } else {
        var temp = [];
        this.selected_job_type.forEach((element) => {
          if (element !== event.target.value) {
            temp.push(event.target.value);
          }
        });
        this.selected_job_type = temp;
      }
      // console.log(this.selected_job_type);
    },
    _jobShiftSelection(event) {
      if (event.target.checked) {
        if (!this.selected_job_shift.includes(event.target.value)) {
          var temp = this.selected_job_shift;
          temp.push(event.target.value);
          this.selected_job_shift = temp;
        }
      } else {
        var temp = [];
        this.selected_job_shift.forEach((element) => {
          if (element !== event.target.value) {
            temp.push(event.target.value);
          }
        });
        this.selected_job_shift = temp;
      }
      // console.log(this.selected_job_shift);
    },
    _jobIndustrySelection(event) {
      if (event.target.checked) {
        if (!this.selected_job_industry.includes(event.target.value)) {
          var temp = this.selected_job_industry;
          temp.push(event.target.value);
          this.selected_job_industry = temp;
        }
      } else {
        var temp = [];
        this.selected_job_industry.forEach((element) => {
          if (element !== event.target.value) {
            temp.push(event.target.value);
          }
        });
        this.selected_job_industry = temp;
      }
    },
    _jobCareerSelection(event) {
      if (event.target.checked) {
        if (!this.selected_job_experience.includes(event.target.value)) {
          var temp = this.selected_job_experience;
          temp.push(event.target.value);
          this.selected_job_experience = temp;
        }
      } else {
        var temp = [];
        this.selected_job_experience.forEach((element) => {
          if (element !== event.target.value) {
            temp.push(event.target.value);
          }
        });
        this.selected_job_experience = temp;
      }
    },
    singleDetail(item) {
      this.detail = item;
    },
    saveJob() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .post(`${API_URL}/save-job`, {
          job_id: this.detail.id,
        })
        .then((response) => {
          this.saveJobSuccess = response.data.msg;

          setTimeout(() => {
            this.saveJobSuccess = "";
          }, 3000);
        });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-half bg-light d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">Jobs / Careers</h4>
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item">
                      <router-link to="/">Home</router-link>
                    </li>
                    <li class="breadcrumb-item" aria-current="page">Jobs</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- Job List Start -->
    <section class="section">
      <div
        class="container"
        style="
          padding-left: 1%;
          max-width: 1400px;
          padding-left: 1%;
          margin-left: 0%;
          width: 100%;
          max-width: 1400px;
          padding-right: 0px;
          margin-right: 0px;
        "
      >
        <div class="row" style="margin-right: -15.5px">
          <div class="col-12">
            <div class="border-bottom">
              <div class="row">
                <div class="col-lg-3 col-md-4">
                  <div class="card">
                    <div class="card-header filter_heading">SALARY RANGE</div>
                    <div class="card-body">
                      <multiselect
                        id="multiselectSalary"
                        v-model="salary_range"
                        :height="300"
                        :options="desiredSalaryOptions"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="Salary Range"
                        label="value"
                        track-by="value"
                        :preselect-first="false"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header filter_heading">City</div>
                    <div class="card-body">
                      <multiselect
                        id="multiselectCities"
                        v-model="city"
                        :height="300"
                        track-by="value"
                        :options="cities"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="City"
                        label="value"
                        :preselect-first="false"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header filter_heading">Date Posted</div>
                    <div class="card-body">
                      <select v-model="date_posted" class="form-control">
                        <option
                          v-for="item in datePostedItems"
                          :key="item.title"
                          :value="item.days"
                        >
                          {{
                            item.value !== "Anytime"
                              ? item.title + `(${item.value})`
                              : item.title
                          }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header filter_heading">Posted By</div>
                    <div class="card-body">
                      <select v-model="posted_by" class="form-control">
                        <option
                          v-for="item in postedByItems"
                          :key="item.title"
                          :value="item.postedBy"
                        >
                          {{
                            item.value !== "Anyone"
                              ? item.title + `(${item.value})`
                              : item.title
                          }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="card" style="margin-top: 2%">
                    <div class="card-header filter_heading">
                      FILTER BY JOB FUNCTIONS
                    </div>
                    <div class="card-body">
                      <ul style="margin-left: 9%">
                        <li v-for="(item, index) in jobFunctions" :key="index">
                          <input
                            type="checkbox"
                            :id="item.job_function_name"
                            :name="item.job_function_name"
                            class="custom-control-input"
                            :value="item.job_function_name"
                            v-on:click="_jobFunctionSelection($event)"
                          /><label
                            class="custom-control-label"
                            :for="item.job_function_name"
                            style="font-size: 12px"
                            >{{ item.job_function_name }}</label
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card" style="margin-top: 2%">
                    <div class="card-header filter_heading">
                      FILTER BY JOB TYPES
                    </div>
                    <div class="card-body">
                      <ul style="margin-left: 9%">
                        <li v-for="(item, index) in jobTypes" :key="index">
                          <input
                            type="checkbox"
                            :id="item.job_type"
                            :name="item.job_type"
                            class="custom-control-input"
                            :value="item.job_type"
                            v-on:click="_jobTypeSelection($event)"
                          /><label
                            class="custom-control-label"
                            :for="item.job_type"
                            style="font-size: 12px"
                            >{{ item.job_type }}</label
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card" style="margin-top: 2%">
                    <div class="card-header filter_heading">
                      FILTER BY JOB SHIFTS
                    </div>
                    <div class="card-body">
                      <ul style="margin-left: 9%">
                        <li v-for="(item, index) in jobShifts" :key="index">
                          <input
                            type="checkbox"
                            :id="item.job_shift"
                            :name="item.job_shift"
                            class="custom-control-input"
                            :value="item.job_shift"
                            v-on:click="_jobShiftSelection($event)"
                          /><label
                            class="custom-control-label"
                            :for="item.job_shift"
                            style="font-size: 12px"
                            >{{ item.job_shift }}</label
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card" style="margin-top: 2%">
                    <div class="card-header filter_heading">
                      FILTER BY CAREER LEVELS
                    </div>
                    <div class="card-body">
                      <ul style="margin-left: 9%">
                        <li v-for="(item, index) in experiences" :key="index">
                          <input
                            type="checkbox"
                            :id="item.career_level"
                            :name="item.career_level"
                            class="custom-control-input"
                            :value="item.career_level"
                            v-on:click="_jobCareerSelection($event)"
                          /><label
                            class="custom-control-label"
                            :for="item.career_level"
                            style="font-size: 12px"
                            >{{ item.career_level }}</label
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card" style="margin-top: 2%">
                    <div class="card-header filter_heading">
                      FILTER BY JOB INDUSTRY
                    </div>
                    <div class="card-body">
                      <ul style="margin-left: 9%">
                        <li v-for="(item, index) in jobIndustries" :key="index">
                          <input
                            type="checkbox"
                            :id="item.industry_name"
                            :name="item.industry_name"
                            class="custom-control-input"
                            :value="item.industry_name"
                            v-on:click="_jobIndustrySelection($event)"
                          /><label
                            class="custom-control-label"
                            :for="item.industry_name"
                            style="font-size: 12px"
                            >{{ item.industry_name }}</label
                          >
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="card hide_by_ch" id="floating_search">
                    <div
                      class="card-body"
                      style="display: flex; justify-content: space-around"
                    >
                      <button
                        type="button"
                        @click="filterSearch"
                        class="btn btn-sm btn-primary"
                        style="margin-top: 5%; bottom: 40px"
                      >
                        Apply
                      </button>

                      <button
                        type="button"
                        @click="clearFilter"
                        class="btn btn-sm btn-light"
                        style="margin-top: 5%"
                      >
                        > Clear
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-lg-5 col-md-9">
                  <div class="row" style="margin-left: -42.5px">
                    <div class="col-lg-12 col-md-7 col-12">
                      <div
                        class="col-lg-12 col-md-4 col-12"
                        v-for="(item, index) in data"
                        :key="index"
                        :style="index > 0 ? 'margin-top: 2%' : 'margin-top: 0%'"
                      >
                        <div
                          class="card job-box rounded border-0 overflow-hidden"
                        >
                          <div class="border-bottom">
                            <div class="position-relative">
                              <img
                                :src="mediaUrl + item.recruiter.banner"
                                class="img-fluid"
                                alt=""
                                style="height: 198px; width: 100%"
                              />
                              <div class="job-overlay bg-white"></div>
                            </div>
                            <h5 class="mb-0 position">
                              <router-link
                                :to="
                                  '/jobs-careers/pakistan/' +
                                  item.location_city
                                    .map((v) => v.toLowerCase())
                                    .join('-') +
                                  '/' +
                                  item.job_title
                                    .toLowerCase()
                                    .replace(/ /g, '-')
                                    .replace(/[^\w-]+/g, '') +
                                  '/' +
                                  item.id
                                "
                                class="text-dark"
                                >{{ item.job_title }}</router-link
                              >
                              <ul class="list-unstyled h6 mb-0 text-warning">
                                <li class="list-inline-item mb-0">
                                  <i class="mdi mdi-star"></i>
                                </li>
                                <li class="list-inline-item mb-0">
                                  <i class="mdi mdi-star"></i>
                                </li>
                                <li class="list-inline-item mb-0">
                                  <i class="mdi mdi-star"></i>
                                </li>
                                <li class="list-inline-item mb-0">
                                  <i class="mdi mdi-star"></i>
                                </li>
                                <li class="list-inline-item mb-0">
                                  <i class="mdi mdi-star"></i>
                                </li>
                              </ul>
                            </h5>
                            <ul class="list-unstyled head mb-0">
                              <li
                                v-for="(_item, _index) in item.job_type"
                                :key="_index"
                                class="badge badge-success badge-pill"
                              >
                                {{ _item }}
                              </li>
                            </ul>
                          </div>

                          <div class="card-body content position-relative">
                            <div
                              class="
                                firm-logo
                                rounded-circle
                                bg-light
                                text-center
                              "
                            >
                              <img
                                :src="mediaUrl + item.recruiter.profile_pic"
                                class="avatar avatar-md-sm"
                                alt=""
                              />
                            </div>
                            <div class="company-detail text-center mt-3">
                              <h5 class="mb-0">
                                <router-link
                                  :to="
                                    '/employer/' +
                                    item.recruiter.company_name
                                      .toLowerCase()
                                      .replace(/ /g, '-')
                                      .replace(/[^\w-]+/g, '')
                                  "
                                  class="text-dark company-name"
                                  >{{
                                    item.recruiter.company_name
                                  }}</router-link
                                >
                              </h5>
                              <p class="text-muted">
                                <a
                                  @click="goToCity(_item)"
                                  class="video-play-icon text-muted"
                                  v-for="(_item, _index) in item.location_city"
                                  :key="_index"
                                  style="cursor: pointer"
                                >
                                  <map-pin-icon
                                    class="fea icon-sm"
                                  ></map-pin-icon>
                                  {{ _item }}
                                </a>
                              </p>
                            </div>

                            <div style="display: flex">
                              <ul
                                class="job-facts list-unstyled"
                                style="
                                  width: 65%;
                                  display: flex;
                                  flex-direction: column;
                                "
                              >
                                <li class="list-inline-item text-muted">
                                  <check-icon
                                    class="fea icon-sm text-success mr-1"
                                  ></check-icon>
                                  {{ item.minimum_experience_years + " years" }}
                                </li>
                                <li class="list-inline-item text-muted">
                                  <check-icon
                                    class="fea icon-sm text-success mr-1"
                                  ></check-icon>
                                  {{ item.job_function }}
                                </li>
                              </ul>
                              <ul
                                class="list-unstyled social-icon mb-0 mt-4"
                                style="width: 35%"
                              >
                                <ShareNetwork
                                  network="twitter"
                                  url="https://news.vuejs.org/issues/180"
                                  title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                                  description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                                  quote="The hot reload is so fast it\'s near instant. - Evan You"
                                  hashtags="job4u_pk"
                                  twitterUser="youyuxi"
                                >
                                  <li
                                    class="list-inline-item ml-1"
                                    style="margin-right: 4%"
                                  >
                                    <a
                                      href="javascript:void(0)"
                                      class="rounded"
                                    >
                                      <twitter-icon
                                        class="fea icon-xs fea-social"
                                      ></twitter-icon>
                                    </a>
                                  </li>
                                </ShareNetwork>

                                <ShareNetwork
                                  network="facebook"
                                  url="https://news.vuejs.org/issues/180"
                                  title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                                  description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                                  quote="The hot reload is so fast it\'s near instant. - Evan You"
                                  hashtags="job4u_pk"
                                >
                                  <li
                                    class="list-inline-item"
                                    style="margin-right: 4%"
                                  >
                                    <a
                                      href="javascript:void(0)"
                                      class="rounded"
                                    >
                                      <facebook-icon
                                        class="fea icon-sm fea-social"
                                      ></facebook-icon>
                                    </a>
                                  </li>
                                </ShareNetwork>
                                <ShareNetwork
                                  network="linkedin"
                                  url="https://news.vuejs.org/issues/180"
                                  title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                                  description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                                  quote="The hot reload is so fast it\'s near instant. - Evan You"
                                  hashtags="job4u_pk"
                                >
                                  <li
                                    class="list-inline-item ml-1"
                                    style="margin-right: 4%"
                                  >
                                    <a
                                      href="javascript:void(0)"
                                      class="rounded"
                                    >
                                      <linkedin-icon
                                        class="fea icon-sm fea-social"
                                      ></linkedin-icon>
                                    </a>
                                  </li>
                                </ShareNetwork>
                              </ul>
                            </div>
                            <button
                              v-on:click="singleDetail(item)"
                              class="btn btn-outline-primary btn-block"
                            >
                              View Details 4
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-7"
                  v-if="detail"
                  style="margin-left: 47.8%; position: fixed; top: 50%"
                  id="filter_detail"
                >
                  <div class="card sidebar rounded border-0 rounded-0">
                    <div
                      class="card-body widget border-bottom"
                      style="display: flex; justify-content: space-between"
                    >
                      <h5 class="mb-0 bold">
                        {{ detail.job_title }} (Applicants:
                        {{ detail.job_application_count }})
                      </h5>

                      <button
                        class="badge badge-danger badge-pill"
                        @click="detail = false"
                      >
                        X
                      </button>
                    </div>

                    <div
                      class="card-body"
                      style="overflow: scroll; height: 500px"
                    >
                      <router-link
                        v-if="auth && !applied"
                        :to="
                          '/jobs-careers/apply/pakistan/' +
                          detail.location_city
                            .map((v) => v.toLowerCase())
                            .join('-') +
                          '/' +
                          detail.job_title
                            .toLowerCase()
                            .replace(/ /g, '-')
                            .replace(/[^\w-]+/g, '')
                        "
                        class="btn btn-sm btn-primary"
                        style="bottom: 40px; margin-left: 2%"
                        >Apply Now <i class="mdi mdi-send"></i
                      ></router-link>

                      <router-link
                        :to="
                          '/jobs-careers/pakistan/' +
                          detail.location_city
                            .map((v) => v.toLowerCase())
                            .join('-') +
                          '/' +
                          detail.job_title
                            .toLowerCase()
                            .replace(/ /g, '-')
                            .replace(/[^\w-]+/g, '') +
                          '/' +
                          detail.id
                        "
                        class="btn btn-sm btn-primary"
                        style="bottom: 40px; margin-left: 2%"
                        >View Detailsas</router-link
                      >
                      <a
                        @href="
                          '/jobs-careers/pakistan/' +
                            detail.location_city
                              .map((v) => v.toLowerCase())
                              .join('-') +
                            '/' +
                            detail.job_title
                              .toLowerCase()
                              .replace(/ /g, '-')
                              .replace(/[^\w-]+/g, '') +
                            '/' +
                            detail.id
                        "
                        >{{ detail.job_title }}</a
                      >
                      <button
                        type="button"
                        v-if="auth"
                        @click="saveJob"
                        class="btn btn-sm btn-primary"
                        style="bottom: 40px; margin-left: 2%"
                      >
                        Save <i class="mdi mdi-save"></i>
                      </button>

                      <button
                        type="button"
                        @click="detail = false"
                        class="btn btn-sm btn-danger"
                        style="bottom: 40px; margin-left: 2%"
                      >
                        Close
                      </button>

                      <div
                        class="alert alert-success"
                        role="alert"
                        style="margin-top: 5%"
                        v-if="saveJobSuccess.length > 0"
                      >
                        {{ saveJobSuccess }}
                      </div>
                      <div
                        class="media widget align-items-center"
                        style="margin-top: 5%"
                      >
                        <user-check-icon
                          class="fea icon-ex-md mr-3"
                        ></user-check-icon>

                        <div class="media-body location_ch">
                          <h4 class="widget-title mb-0 bold detail_heading">
                            Job Shift:
                          </h4>
                          <p
                            class="text-primary mb-0 detail_body"
                            v-for="(_item, _index) in detail &&
                            detail.job_shift"
                            :key="_index"
                          >
                            {{ _item }}
                          </p>
                        </div>
                      </div>

                      <div class="media widget align-items-center mt-3">
                        <user-check-icon
                          class="fea icon-ex-md mr-3"
                        ></user-check-icon>
                        <div class="media-body">
                          <h4 class="widget-title mb-0 bold detail_heading">
                            Employment Type:
                          </h4>
                          <p
                            class="text-primary mb-0 detail_body"
                            v-for="(_item, _index) in detail && detail.job_type"
                            :key="_index"
                          >
                            {{ _item }}
                          </p>
                        </div>
                      </div>

                      <div class="media widget align-items-center mt-3">
                        <map-pin-icon
                          class="fea icon-ex-md mr-3"
                        ></map-pin-icon>
                        <div class="media-body">
                          <h4 class="widget-title mb-0 bold detail_heading">
                            Location:
                          </h4>
                          <p
                            class="text-primary mb-0 detail_body"
                            v-for="(_item, _index) in detail &&
                            detail.location_city"
                            :key="_index"
                          >
                            {{ _item }}
                          </p>
                        </div>
                      </div>

                      <div class="media widget align-items-center mt-3">
                        <monitor-icon
                          class="fea icon-ex-md mr-3"
                        ></monitor-icon>
                        <div class="media-body">
                          <h4 class="widget-title mb-0 bold detail_heading">
                            Job Sector:
                          </h4>
                          <p class="text-primary mb-0 detail_body">
                            {{ detail && detail.job_function }}
                          </p>
                        </div>
                      </div>

                      <div class="media widget align-items-center mt-3">
                        <briefcase-icon
                          class="fea icon-ex-md mr-3"
                        ></briefcase-icon>
                        <div class="media-body">
                          <h4 class="widget-title mb-0 bold detail_heading">
                            Experience:
                          </h4>
                          <p class="text-primary mb-0 detail_body">
                            {{
                              detail &&
                              detail.minimum_experience_years + " years"
                            }}
                          </p>
                        </div>
                      </div>

                      <div class="media widget align-items-center mt-3">
                        <book-icon class="fea icon-ex-md mr-3"></book-icon>
                        <div class="media-body">
                          <h4 class="widget-title mb-0 bold detail_heading">
                            Qualifications:
                          </h4>
                          <p class="text-primary mb-0 detail_body">
                            {{ detail && detail.education }}
                          </p>
                        </div>
                      </div>

                      <div class="media widget align-items-center mt-3">
                        <dollar-sign-icon
                          class="fea icon-ex-md mr-3"
                        ></dollar-sign-icon>
                        <div class="media-body">
                          <h4 class="widget-title mb-0 bold detail_heading">
                            Salary:
                          </h4>
                          <p class="text-primary mb-0 detail_body">
                            +{{ detail && detail.salary_min }} to
                            {{ detail && detail.salary_max }}
                          </p>
                        </div>
                      </div>

                      <div class="media widget align-items-center mt-3">
                        <clock-icon class="fea icon-ex-md mr-3"></clock-icon>
                        <div class="media-body">
                          <h4 class="widget-title mb-0 bold detail_heading">
                            Date posted:
                          </h4>
                          <p class="text-primary mb-0 mb-0 detail_body">
                            {{
                              moment(detail.created_at).format("MMMM Do YYYY")
                            }}
                          </p>
                        </div>
                      </div>

                      <div class="media widget align-items-center mt-3">
                        <clock-icon class="fea icon-ex-md mr-3"></clock-icon>
                        <div class="media-body">
                          <h4 class="widget-title mb-0 bold detail_heading">
                            Application deadline:
                          </h4>
                          <p class="text-primary mb-0 mb-0 detail_body">
                            {{ moment(detail.apply_by).format("MMMM Do YYYY") }}
                          </p>
                        </div>
                      </div>

                      <div class="media widget align-items-center mt-3">
                        <clock-icon class="fea icon-ex-md mr-3"></clock-icon>
                        <div class="media-body">
                          <h4 class="widget-title mb-0 bold detail_heading">
                            Job Description
                          </h4>
                          <p
                            id="temp"
                            class="para-desc mx-auto text-muted detail_body"
                            v-html="detail.job_description"
                          ></p>
                          <!-- <p id="temp1" class="text-primary mb-0 mb-0"></p> -->
                        </div>
                      </div>

                      <router-link
                        v-if="auth && !applied"
                        :to="
                          '/jobs-careers/apply/pakistan/' +
                          detail.location_city
                            .map((v) => v.toLowerCase())
                            .join('-') +
                          '/' +
                          detail.job_title
                            .toLowerCase()
                            .replace(/ /g, '-')
                            .replace(/[^\w-]+/g, '')
                        "
                        class="btn btn-sm btn-primary"
                        style="margin-top: 5%; bottom: 40px; margin-left: 2%"
                        >Apply Now <i class="mdi mdi-send"></i
                      ></router-link>

                      <router-link
                        :to="
                          '/jobs-careers/pakistan/' +
                          detail.location_city
                            .map((v) => v.toLowerCase())
                            .join('-') +
                          '/' +
                          detail.job_title
                            .toLowerCase()
                            .replace(/ /g, '-')
                            .replace(/[^\w-]+/g, '') +
                          '/' +
                          detail.id
                        "
                        class="btn btn-sm btn-primary"
                        style="margin-top: 5%; bottom: 40px; margin-left: 2%"
                        >View Details</router-link
                      >

                      <button
                        type="button"
                        v-if="auth"
                        @click="saveJob"
                        class="btn btn-sm btn-primary"
                        style="margin-top: 5%; bottom: 40px; margin-left: 2%"
                      >
                        Save <i class="mdi mdi-save"></i>
                      </button>

                      <button
                        type="button"
                        @click="detail = false"
                        class="btn btn-sm btn-danger"
                        style="margin-top: 5%; bottom: 40px; margin-left: 2%"
                      >
                        Close
                      </button>

                      <div
                        class="alert alert-success"
                        role="alert"
                        style="margin-top: 10%"
                        v-if="saveJobSuccess.length > 0"
                      >
                        {{ saveJobSuccess }}
                      </div>
                    </div>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <!--end row-->
        <Skeleton v-if="loading" />
        <div class="col-md-12 mt-10" style="text-align: center">
          <button
            type="button"
            @click="loadmore"
            class="btn btn-primary"
            v-if="show_load_more"
          >
            Load More Jobs
          </button>
        </div>
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Job List End -->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
